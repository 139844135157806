<template>
  <button class="containerPillClick" :disabled="isDisabled">
    <div class="containerPillClick_img">
      <img
        :src="
          data.url_cover !== null
            ? data.url_cover
            : (data.diary.file_upload && data.diary.file_upload != ''
                ? data.diary.file_upload
                : '@/assets/logo.png')
        "
        class="cover-image"
      />
    </div>
    <div class="containerTitleAndButtonsList">
      <div class="containerTitleList">
        <span class="titleList--text">
          {{ data.area_name || "Titulo no definido" }}</span
        >
        <span class="subtitleList--text">{{
          cutShortString(data.pill_name) || "Sin descripción"
        }}</span>
      </div>
      <div class="containerBtnsList">
        <button class="containerBtn--seeRigthNow" :disabled="isDisabled">
          <v-icon class="mr-2" color="white">{{ icon }}</v-icon>
          <span @click="HandlerShowContent(data)">Ver Ahora</span>
        </button>
        <button 
          v-if="!hiddenFav" 
          class="ml-2" 
          @click="HandlerAddFavorite"
          :disabled="isDisabled"
        >
          <div
            :class="
              isFavorite === true || data.favorite
                ? 'circleIcon white-10 favoriteClass'
                : 'circleIcon white-10 '
            "
          >
            <v-icon v-if="isFavorite === true || data.favorite" color="white"
              >mdi-heart</v-icon
            >
            <v-icon v-else>mdi-heart-outline</v-icon>
          </div>
        </button>
      </div>
    </div>
  </button>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    icon: {
      type: String,
      default: "",
    },
    favoriteDefault: {
      type: Boolean,
      default: false,
    },
    hiddenFav: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      isFavorite: false,
    };
  },
  mounted() {
    this.isFavorite = this.favoriteDefault;
  },
  computed: {
    isDisabled() {
      return this.index !== 0;
    }
  },
  methods: {
    async HandlerAddFavorite() {
      try {
        if (this.data.whatsapp_accounts.length == 0) {
          return;
        } else {
          this.loading = true;
          const request = { content_id: this.data.id };
          const response = await this.$store.dispatch(
            "profile/LIST_CONTENT_FAVORITE",
            request
          );
          this.isFavorite = true;
          this.$emit("cardList:change", response.data.data);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async HandlerShowContent(content) {
      if (content.whatsapp_accounts.length == 0) {
        return () => {};
      } else {
        const request = { content_id: content.id };

        const response = await this.$store.dispatch(
          "profile/ENCRYPT_CONTENT",
          request
        );

        const data_encrypt = response.data.public_key;

        const encryptData = this.CryptoJS.AES.encrypt(
          data_encrypt,
          "MyEncrypt-dAtA-sEcrEt"
        ).toString();

        sessionStorage.setItem("Value-rec", encryptData);
        this.$router.push({
          name: "show_content",
          query: { index: response.data.data_encrypt },
        });
      }
    },
    cutShortString(string, longitud = 75) {
      if (string.length > longitud) {
        string = string.substring(0, longitud);
        string += "...";
      }
      return string;
    },
  },
};
</script>

<style scoped>
@media (max-width: 768px) {
  .containerPillClick_img {
    margin-top: 1rem !important;
  }
  .containerPillClick {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .containerTitleAndButtonsList {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
  .containerBtnsList {
    display: flex !important;
    /* flex-direction: column; */
    gap: 1rem !important;
  }
  .titleList--text,
  .subtitleList--text {
    max-width: 215px !important;
  }
}
.favoriteClass {
  background-color: #f1416c !important;
}
.imgContainTT {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 145px;
  height: 145px;
  /* border-radius: 12px; */
}
.white-10 {
  background-color: #a4a4a438;
}

.circleIcon {
  width: 35px;
  height: 35px;
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  /* overflow: hidden; */
  border-radius: 100px;
  padding: 5px;
  justify-content: center;
}

.containerIconBtn--seeRigthNow {
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.containerBtn--seeRigthNow {
  background: #0076ff 0% 0% no-repeat padding-box;
  border-radius: 32px;
  /* height: 36px; */
  text-align: center;
  font: normal normal normal 13px/30px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  padding: 9px 20.5px 8px 36.5px;
  padding: 9px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.containerBtnsList {
  display: flex;
  align-items: center;
  /* margin-left: 2rem; */
}

.subtitleList--text {
  text-align: left;
  font: normal normal 300 12px/40px Poppins;
  letter-spacing: 0px;
  color: #6f7b87;
}
.titleList--text {
  text-align: left;
  font: normal normal bold 15px/23px Poppins;
  letter-spacing: 0px;
  color: #101820;
}
.containerTitleList {
  display: flex;
  flex-direction: column;
  text-align: left;
  /* margin-left: 2rem; */
}
.containerPillClick {
  width: 100%;
  /* height: 145px; */
  display: flex;
  /* background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box; */
  border: 1px solid var(--unnamed-color-dfdfdf);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dfdfdf;
  border-radius: 8px;
  opacity: 1;
}
.cover-image {
  width: 100%; 
  height: auto;
  object-fit: cover;
}
.containerTitleAndButtonsList {
  display: flex;
  padding: 21px 21px 7px 21px;
  flex-direction: column;
  width: 100%;
}
.containerPillClick_img {
  border-radius: 8px 0px 0px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 165px;
  height: 145px;
  margin-top: 0px !important;
}
button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}
</style>
