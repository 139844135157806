var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"containerPillClick",attrs:{"disabled":_vm.isDisabled}},[_c('div',{staticClass:"containerPillClick_img"},[_c('img',{staticClass:"cover-image",attrs:{"src":_vm.data.url_cover !== null
          ? _vm.data.url_cover
          : (_vm.data.diary.file_upload && _vm.data.diary.file_upload != ''
              ? _vm.data.diary.file_upload
              : '@/assets/logo.png')}})]),_c('div',{staticClass:"containerTitleAndButtonsList"},[_c('div',{staticClass:"containerTitleList"},[_c('span',{staticClass:"titleList--text"},[_vm._v(" "+_vm._s(_vm.data.area_name || "Titulo no definido"))]),_c('span',{staticClass:"subtitleList--text"},[_vm._v(_vm._s(_vm.cutShortString(_vm.data.pill_name) || "Sin descripción"))])]),_c('div',{staticClass:"containerBtnsList"},[_c('button',{staticClass:"containerBtn--seeRigthNow",attrs:{"disabled":_vm.isDisabled}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"white"}},[_vm._v(_vm._s(_vm.icon))]),_c('span',{on:{"click":function($event){return _vm.HandlerShowContent(_vm.data)}}},[_vm._v("Ver Ahora")])],1),(!_vm.hiddenFav)?_c('button',{staticClass:"ml-2",attrs:{"disabled":_vm.isDisabled},on:{"click":_vm.HandlerAddFavorite}},[_c('div',{class:_vm.isFavorite === true || _vm.data.favorite
              ? 'circleIcon white-10 favoriteClass'
              : 'circleIcon white-10 '},[(_vm.isFavorite === true || _vm.data.favorite)?_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-heart")]):_c('v-icon',[_vm._v("mdi-heart-outline")])],1)]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }